<template>
    <div class="w-full py-2 flex items-center justify-between min-w-full">
        <div class="flex gap-4 items-center">
            <div v-if="backButton || showSearchBar">
                <Button @click="handleBack" v-if="backButton" icon="angle-left" text="Back" type="primary" outline> </Button>
                <Input v-if="showSearchBar" :changeCase="true" :shadow="false" placeholder="Search" bg="#fff" margin="0" class="h-9" v-model="searchValue" @input="handleSearchFilter" />
            </div>
            <BreadCrumbs :showOnlyCustomCrumbs="showOnlyCustomCrumbs" :customCrumbLists="customCrumbs" v-if="showBreadCrumbs" />

            <template>
                <slot name="header-filters"> </slot>
            </template>
        </div>

        <div class="flex gap-4">
          <!-- class="inline-block px-6 py-2.5 pl-5 pr-5 text-white-text font-medium text-xs leading-tight uppercase rounded-xl shadow-md hover:bg-blue-700 hover:shadow-lg capitalize focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
          style="background:#0D69D5" -->
            <template>
                <slot name="actions">
                  <Dropdown
                    searchable
                    :allow-empty='false'
                    width="200px"
                    :options="dropDownOptions.options"
                    placeholder="Client Name"
                    v-if="showDropDown"
                    v-model="dropDownOptions.defaultValue"
                    :config="{ label: dropDownOptions.title, trackBy: dropDownOptions.value }"
                    class="bg-primary"
                    @input="handleDropDownSelection(dropDownOptions.defaultValue)"
                    :style="{
                      minHeight: '42px',
                      'color': 'black',
                      'border-radius': '14px'        
                                        
                    }"
                   >
                    </Dropdown>
                  <Button type="primary" v-if="mode && showBtn" @click="handleClick" :text="buttonText" :disabled="disabled">
                        <!-- <font-awesome-icon class="text-base mr-4" :icon="buttonIcon ? buttonIcon : 'plus'" /> -->
                    </Button>
                </slot>
            </template>
        </div>
    </div>
</template>
<script>
import Input from "@/components/input";
import Button from "@/components/button";
import BreadCrumbs from "@/components/bread-crumbs/bread-crumbs";
import Dropdown from "@shared/components/dropdown-base";
export default {
    name: "sub-header",
    props:{
      showBreadCrumbs:{
        type:Boolean,
        default: true
      },
      backButton:{
        type: Boolean,
        default: false
      },
      setEditModeUnlock:{
        type: Function,
        default: () => {}
      },
      handleBackSection:{
        type: Function,
        default: () => {}
      },
      showInputTypeModal:{
        type:Boolean,
        default: false
      },
      handleClick:{
        type: Function,
        default: () => {}
      },
      buttonText:{
        type:String,
        deafult:""
      },
      backRedirectionUrl:{
        type:String,
        default: ''
      },
      showBtn:{
        type:Boolean,
        default: false
      },
      disabled:{
        type:Boolean,
        default: false
      },
      handleSearchFilter:{
        type: Function,
        default: () => {}
      },
      showSearchBar:{
        type:Boolean,
        default: false
      },
      buttonIcon:{
        type: String,
        default: ''
      },
      showOnlyCustomCrumbs:{
        type: Boolean,
        default: false
      },
      customCrumbLists:{
        type: Array,
        deafult: () => []
      },
      showDropDown: {
        type: Boolean,
        default: false
      },
      dropDownOptions: {
        type: Object ,
        default: () => {}
      },
      handleDropDownSelection: {
        type: Function,
        default: () => {}
      },

  mode:{
        type: Boolean,
        default: true
      },

    },
    

    data() {
        return {
            searchValue: "",
        };
    },
    methods: {
        handleBack() {
            this.setEditModeUnlock()
            this.handleBackSection()
            if (this.backRedirectionUrl) {
                this.$router.push({ path: this.backRedirectionUrl });
            } else {
                this.$router.back();
            }
        },
        handleChange() {
            this.handleSearchFilter(this.searchValue);
        },
        selected(crumb) {
            console.log(crumb);
        },
    },
    computed: {
        filteredList() {
            return this.users.filter((post) => {
                return post.user_name.toLowerCase().includes(this.searchValue.toLowerCase());
            });
        },
        customCrumbs(){
          return this.customCrumbLists
        }
    },
    components: {
        Input,
        Button,
        BreadCrumbs,
        Dropdown,
    },
};
</script>
<style scoped>
.field__Card svg {
    color: rgb(82, 82, 82);
}
</style>
